<template>
  <router-view v-slot="{ Component, route }">
    <component :is="Component">
      <slot name="content"/>
    </component>
  </router-view>
  <Error/>
</template>

<script setup lang="ts">

declare global {
  interface Window {
    csrfTokenName: string;
    csrfTokenValue: string;
    user: any;

    jwt: {
      jwt: string;
      jwtExpiresAt: number;
      refreshToken: string;
      refreshTokenExpiresAt: number;
    };
  }
}

// components
import {useUserStore} from "@/stores/userStore";

import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import craft from "@/api/craft";
import wishlist from "./stores/wishlist";

import Error from "./util/Error.vue";

import index from "./router/index.ts";
import indexEN from "./router/indexEN.ts";
import routerStore from "./stores/router";
import {isCurrentEnglish, setSiteId} from "@/stores/siteid";

const route = useRoute();

const router = () => {
  setSiteId();
  if (window.location.pathname.includes("/eng") || window.location.hostname.endsWith("com")) {
    routerStore.value = indexEN;
    return indexEN;
  } else {
    routerStore.value = index;
    return index;
  }
}

// controls for the error message

const props = defineProps<{
  csrfname: string;
  csrftoken: string;
  list: number;
}>();

onMounted(() => {
  initObserver();

  if (props.list) {
    if (props.list != 0) {
      craft.getWishlistItems(props.list).then((res) => {
        if (res) {
          if (res.data && res.data.wishlistItems.length > 0) {
            let items = res.data.wishlistItems
                .filter((x) => x.element != null)
                .map((item: any) => {
                  if (item.element) return item.element.id;
                });
            if (items.length > 0) {
              wishlist.value = {
                wishlistId: props.list,
                wishlistItems: items,
              };
            }
          }
        }
      });
    }
  }
});

const targetNode = ref<HTMLElement | null>(null);
const observerInit = ref(false);
const buttonClicked = ref(false);

function initObserver() {
  targetNode.value = document.getElementById('openProductoffcanvas');
  if (targetNode.value) {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-show') {
          buttonClicked.value = targetNode.value?.getAttribute('data-show') === 'true';
        }
      }
    });
    observer.observe(targetNode.value, {attributes: true});
    observerInit.value = true;
  }
}

function getHousetitle() {
  return document.getElementById('initialHousetitle')?.getAttribute('data-title') ?? '';
}

// Function to track page views
function trackPageView() {
  if (window._paq) {
    window._paq.push(['setCustomUrl', window.location.pathname]);
    window._paq.push(['setDocumentTitle', document.title]);
    window._paq.push(['trackPageView']);
  }
}

// Function to observe changes in the document title
function observeTitleChanges() {
  const titleElement = document.querySelector('title');
  let prevTitle = '';
  if (titleElement) {
    const observer = new MutationObserver(() => {
      if (document.title !== prevTitle) {
        prevTitle = document.title;
        trackPageView();
      }
    });

    observer.observe(titleElement, {childList: true});
  }
}

// Initial page view tracking
onMounted(() => {
  trackPageView();
  // observeTitleChanges();
});


// watch(
//     () => loaded.value.active,
//     () => {
//         if (!loaded.value.active) {
//             const el = document.body.querySelector("#loading") as HTMLElement;
//             el.style.opacity = "0";

//             setTimeout(() => {
//                 el.remove();
//             }, 300);
//         }
//     },
// );

// watch(
//     () => route.name,
//     () => {
//       // scroll to top when animation is finished
//       setTimeout(() => {
//         window.scrollTo(0, 0);
//       }, 400);
//     },
// );
</script>

<style lang="css">
.slide-right-leave-active,
.slide-left-leave-active {
  transition: transform 0.4s ease-in-out, opacity 0.2s ease;
}

.slide-right-enter-active,
.slide-left-enter-active {
  transition: opacity 0.2s;
}

.slide-right-enter-from,
.slide-left-enter-from {
  opacity: 0;
}

.slide-right-leave-to {
  transform: translateX(100%);
  opacity: 0.9;
}

.slide-left-leave-to {
  transform: translateX(-100%);
  opacity: 0.9;
}
</style>
