<template>
    <div :class="`bg-gray w-full group rounded-lg pb-12 relative inline-block ${!is_touch ? 'lg:pb-0' : ''}`">
        <a :href="'/' + url">
            <div
                :class="`overflow-hidden aspect-[1.3] rounded-lg ${!is_touch ? 'lg:relative lg:after:absolute lg:after:top-0 lg:after:left-0 lg:after:w-full lg:after:h-full lg:after:bg-gradient-to-t lg:after:from-black lg:after:to-[#00000000] lg:after:opacity-35 lg:after:rounded-lg' : ''} `">
                <Image v-if="img"
                    class="w-full aspect-[1.3] object-cover rounded-lg scale-100 duration-300 transition-transform group-hover:scale-105"
                    :src="img.url" :srcset="img.srcset" :img="img" :alt="img.alt ? img.alt : ''" :title="img.title ? img.title : ''"/>
            </div>
            <div
                :class="`px-4 pt-3 w-full space-y-4 relative ${!is_touch ? 'lg:absolute lg:bottom-8 xl:bottom-3 2xl:bottom-5 group-hover:lg:translate-y-1/3 group-hover:2xl:translate-y-1/2 lg:px-8 lg:pb-4 ' : ''}  transition-transform duration-300 translate-y-0`">
                <div
                    :class="`flex justify-between flex-col items-start ${!is_touch ? 'lg:gap-2 2xl:flex-row xl:relative' : ''}`">
                    <h3 :class="`${!is_touch ? 'lg:text-white max-lg:pr-10 lg:text-xl lg:drop-shadow-customHeadline translate-y-0 transition-transform group-hover:lg:-translate-y-4 group-hover:2xl:translate-y-0' : ''}`"
                        v-text="heading"></h3>
                    <div
                        :class="`hidden ${!is_touch ? 'lg:gap-2 2xl:flex-row xl:relative lg:pt-4 2xl:pt-3 lg:block min-w-[209px] lg:absolute 2xl:static lg:right-4 xl:right-0 lg:top-[20%] xl:pt-0 xl:top-full space-x-4 opacity-0 -translate-y-1 transition-all xl:h-0 xl:group-hover:h-max group-hover:opacity-100 group-hover:translate-y-0' : ''}`">
                        <template v-if="id && +id != 0 && wishlist">
                            <v-btn v-if="wishlist.wishlistItems.includes(id.toString())" @click="RemoveFromWishlist"
                                size="small" :rounded="true" height="40" width="40" class="!p-0 !min-w-[40px]">
                                <HeartSolid class="w-6" />
                                <span class="btn-icon hidden">{{ getTranslationByKey('Aus der Merkliste entfernen') }}</span>
                            </v-btn>
                            <v-btn v-else @click="AddToWishlist" size="small" :rounded="true" height="40" width="40"
                                class="!p-0 !min-w-[40px]">
                                <Heart class="w-6 stroke-2" />
                                <span class="btn-icon hidden">{{ getTranslationByKey('Zur Merkliste hinzufügen') }}</span>
                            </v-btn>
                        </template>
                        <v-btn-lite class="text-primary btn-lite">{{ getTranslationByKey('Infos und Kontakt') }}</v-btn-lite>
                    </div>
                </div>
                <div :class="`flex flex-wrap items-center gap-3 opacity-100 transition-opacity duration-300 ${!is_touch ? 'group-hover:lg:opacity-0' : ''}`">
                    <div v-if="houseStyles" class="bg-white px-3 text-sm py-1 rounded-full">{{houseStyles}}</div>
                    <div v-if="realEstateTypes" class="bg-white px-3 text-sm py-1 rounded-full">{{realEstateTypes}}</div>
                    <div v-if="totalLivingArea && totalLivingArea > 0" class="bg-white px-3 text-sm py-1 rounded-full">{{totalLivingArea}} m²</div>
                    <div v-if="promotionalPrice" class="bg-white px-3 text-sm py-1 rounded-full flex flex-nowrap items-center gap-1">
                      <span>ab {{ thousandFormat(promotionalPrice) }} €</span>
                    </div>
                </div>
                <div :class="`absolute translate-y-[-100%] top-0 right-4 ${!is_touch ? 'lg:hidden' : ''}`">
                    <template v-if="id && +id != 0 && wishlist">
                        <v-btn v-if="wishlist.wishlistItems.includes(id.toString())" @click="RemoveFromWishlist" size="small"
                            :rounded="true" height="40" width="40" class="btn-icon !p-0 !min-w-[40px]" :aria-label="getTranslationByKey('Aus der Merkliste entfernen')">
                            <HeartSolid class="w-6" />
                        </v-btn>
                        <v-btn v-else @click="AddToWishlist" size="small" :rounded="true" height="40" width="40" :aria-label="getTranslationByKey('Zur Merkliste hinzufügen')"
                            class="btn-icon !p-0 !min-w-[40px]">
                            <Heart class="w-6 stroke-2" />
                        </v-btn>
                    </template>
                </div>
            </div>
            <div :class="`absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 ${!is_touch ? 'lg:hidden' : ''}`">
                <v-btn class="text-white">{{ getTranslationByKey('Infos und Kontakt') }}</v-btn>
            </div>
        </a>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import type { Image } from "@/types/elements";

import craft from "@/api/craft";
import wishlist from "@/stores/wishlist";
import errorStore from "@/stores/error";

import { is_touch_enabled } from "@/util/detecter";
import Star from "@/icons/Star.vue";
import {getTranslationByKey} from "@/stores/translation";

const active = ref(false);
const is_touch = is_touch_enabled();

const props = defineProps<{
    img: Image;
    heading: string;
    url: string;
    id: string | number;
    houseStyles: string;
    realEstateTypes: string;
    totalLivingArea: string;
    promotionalPrice: string;
}>();

function AddToWishlist(e: any) {
    e.preventDefault();
    craft.AddToWihlist(props.id.toString()).then((data: any) => {
        if (data.success && data.action === "add") {
            const list: string[] =
                wishlist.value?.wishlistItems &&
                    wishlist.value?.wishlistItems.length > 0
                    ? wishlist.value.wishlistItems
                    : [];
            list.push(props.id.toString());
            wishlist.value.wishlistItems = list;
            wishlist.value.wishlistId = data.id;
        } else {
            if (data?.error) {
                errorStore.value.message = data.error;
                errorStore.value.active = true;
            }
        }
    });
}

function thousandFormat(number: string) {
  return Number(number).toLocaleString();
}

function RemoveFromWishlist(e: any) {
    e.preventDefault();
    craft.RemoveFromWihlist(props.id.toString()).then((data: any) => {
        if (data.success && data.action === "remove") {
            let list = wishlist.value.wishlistItems;
            list = list.filter((item: any) => item != props.id);
            wishlist.value.wishlistItems = list;
            wishlist.value.wishlistId = data.id;
        } else {
            if (data?.error) {
                errorStore.value.message = data.error;
                errorStore.value.active = true;
            }
        }
    });
}
</script>
